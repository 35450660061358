import { Request } from 'src/app/models/request-message';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocalPagerService } from './local-pager.service';
import { Observable } from 'rxjs';
import { HttpEvent } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
//import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManageTicketService {
  baseURL = environment.apiUrl;

  constructor(private http: HttpClient, private pagerService: LocalPagerService) { }

  getFilterDropDowns() {
    return this.http.get<any>(this.baseURL + 'ManageTicket/GetFilterDropDowns');
  }
  getTickets() {
    return this.http.get<any>(this.baseURL + 'ManageTicket/GetTickets');
  }
  getTicketById(ticketId) {
    return this.http.get<Request>(this.baseURL + 'ManageTicket/GetTicketById?ticketId=' + ticketId);
  }
  addTicketReply(currentTicketId,newReplyMessage,adminEmail) {
    return this.http.get<Request>(this.baseURL + 'ManageTicket/AddTicketReply?ticketId=' + currentTicketId+"&replyMessage="+newReplyMessage+"&adminEmail="+adminEmail);
  }
  changeTicketStatus(currentTicketId,ticketStatus) {
    return this.http.get<Request>(this.baseURL + 'ManageTicket/ChangeTicketStatus?ticketId=' + currentTicketId+"&ticketStatusNumber="+ ticketStatus);
  }
  exportResult(filter) {//: Observable<HttpEvent<Blob>>
    debugger;
    // {
    //   return this.http.request(new HttpRequest(
    //     'GET',
    //     `${this.baseURL + 'ManageTicket/GetTicketsForExport',filter}`,
    //     null,
    //     {
    //       reportProgress: true,
    //       responseType: 'blob'
    //     }));
    // }
    return this.http.post<Request>(this.baseURL + 'ManageTicket/GetTicketsForExport', filter);
  }
  public downloadFile(path): Observable<HttpEvent<Blob>> 
  {
    debugger;
    return this.http.request(new HttpRequest(
      'GET',
      `${this.baseURL + 'ManageTicket/Download?path='+path}`,
      null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
}
