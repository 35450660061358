import { Request } from 'src/app/models/request-message';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocalPagerService } from './local-pager.service';

@Injectable({
  providedIn: 'root'
})
export class ManageRfidcardService {
  baseURL = environment.apiUrl;

  constructor(private http: HttpClient, private pagerService: LocalPagerService) { }

  getFilterDropDowns() {
    return this.http.get<any>(this.baseURL + 'ManageRFIDCard/GetFilterDropDowns');
  }
  getOwner() {
    var filter= { 'ownerName':""};
    return this.http.post<any>(this.baseURL + 'ManageRFIDCard/GetOwner',filter);

  }

  getRFIDCards() {
    return this.http.get<any>(this.baseURL + 'ManageRFIDCard/GetRFIDCards');
  }
  getRFIDCardById(rFIDCardId) {
    debugger;
    return this.http.get<Request>(this.baseURL + 'ManageRFIDCard/GetRFIDCardById?rFIDCardId=' + rFIDCardId);
  }
  addRFIDCard(rFIDCard) {
    debugger;
    return this.http.post<Request>(this.baseURL + 'ManageRFIDCard/AddRFIDCard', rFIDCard);
  }

  updateRFIDCard(rFIDCard) {
    return this.http.post<Request>(this.baseURL + 'ManageRFIDCard/UpdateRFIDCard', rFIDCard);
  }

  deleteRFIDCard(rFIDCardId) {
    debugger;
    return this.http.get<Request>(this.baseURL + 'ManageRFIDCard/DeleteRFIDCard?rFIDCardId=' + rFIDCardId);
  }
  GetRIFDCardsbyOwnerId(ownerId)
  {
  debugger;
    return this.http.get<any>(this.baseURL + 'ManageRFIDCard/GetRFIDCardsByOwnerId?ownerId=' + ownerId);

  }
  GetRIFDCardsbyEmployeeId(employeeId)
  {
  debugger;
    return this.http.get<any>(this.baseURL + 'ManageRFIDCard/GetRFIDCardsByEmployeeId?employeeId=' + employeeId);

  }
  GetRIFDCardsbyWorkerId(workerId)
  {
  debugger;
    return this.http.get<any>(this.baseURL + 'ManageRFIDCard/GetRFIDCardsByWorkerId?workerId=' + workerId);

  }
}

