import { Request } from 'src/app/models/request-message';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocalPagerService } from './local-pager.service';

@Injectable({
  providedIn: 'root'
})
export class ManageUnitService {
  baseURL = environment.apiUrl;

  constructor(private http: HttpClient, private pagerService: LocalPagerService) { }

  getFilterDropDowns() {
    return this.http.get<any>(this.baseURL + 'ManageCommunityUnit/GetFilterDropDowns');
  }
  getSubCatgory() {
    var filter= { 'subcatogryname':""};
    return this.http.post<any>(this.baseURL + 'manageCategorySubcategory/GetSubCategory',filter);

  }

  getCommunityUnits() {
    return this.http.get<any>(this.baseURL + 'ManageCommunityUnit/GetCommunityUnits');
  }
  getCommunityUnitById(communityUnitId) {
    return this.http.get<Request>(this.baseURL + 'ManageCommunityUnit/GetCommunityUnitById?communityUnitId=' + communityUnitId);
  }
  addCommunityUnit(communityUnit) {
    return this.http.post<Request>(this.baseURL + 'ManageCommunityUnit/AddCommunityUnit', communityUnit);
  }

  updateCommunityUnit(communityUnit) {
    return this.http.post<Request>(this.baseURL + 'ManageCommunityUnit/UpdateCommunityUnit', communityUnit);
  }

  deleteCommunityUnit(communityUnitId) {
    return this.http.get<Request>(this.baseURL + 'ManageCommunityUnit/DeleteCommunityUnit?communityUnitId=' + communityUnitId);
  }

  getAllUnitsForDropDown() {
    return this.http.get<any>(this.baseURL + 'ManageCommunityUnit/GetFilterDropDownsUnits');
  } 

  UpdateMustReadCardAndStickerToOpenRequest(unitId,isMustReadCardAndSticker) {
    return this.http.get<any>(this.baseURL + 'ManageCommunityUnit/UpdateMustReadCardAndStickerToOpenBool?unitId=' + unitId+'&isMustReadCardAndSticker='+isMustReadCardAndSticker);
  }
}
