import { Request } from 'src/app/models/request-message';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocalPagerService } from './local-pager.service';

@Injectable({
  providedIn: 'root'
})
export class ManageEmployeeService {
  baseURL = environment.apiUrl;

  constructor(private http: HttpClient, private pagerService: LocalPagerService) { }
 
  

  addEmployee(Emp) {
    debugger;
    return this.http.post<Request>(this.baseURL + 'ManageEmployee/AddEmployee', Emp);
  }

  updateEmployee(Emp) {
   
    debugger;
    return this.http.post<Request>(this.baseURL + 'ManageEmployee/UpdateEmployee', Emp);
  }//

  deleteEmployee(employeeId) {
    debugger;
    return this.http.get<any>(this.baseURL + 'ManageEmployee/DeleteEmployee?employeeId=' + employeeId);
  }

  getEmployeeById(employeeId)
  {
    debugger;
    return this.http.get<any>(this.baseURL + 'ManageEmployee/GetEmployeeById?employeeId=' + employeeId);
       
  }
  
}
