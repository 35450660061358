import { Request } from 'src/app/models/request-message';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocalPagerService } from './local-pager.service';
import { Observable } from 'rxjs';
import { HttpEvent } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManagePermissionService {
  baseURL = environment.apiUrl;

  constructor(private http: HttpClient, private pagerService: LocalPagerService) { }

  
  getPermissions() {
    return this.http.get<any>(this.baseURL + 'ManagePermission/GetPermissions');
  }
  getPermissionById(permissionId) {
    return this.http.get<Request>(this.baseURL + 'ManagePermission/GetPermissionById?permissionId=' + permissionId);
  }
  
  
  exportResult(filter) {
    debugger;
   
    return this.http.post<Request>(this.baseURL + 'ManagePermission/GetPermissionsForExport', filter);
  }
  public downloadFile(path): Observable<HttpEvent<Blob>> 
  {
    debugger;
    return this.http.request(new HttpRequest(
      'GET',
      `${this.baseURL + 'ManagePermission/Download?path='+path}`,
      null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  getAttachmentByPermissionId(permissionId) {
    return this.http.get<Request>(this.baseURL + 'ManagePermission/GetAttachmentByPermissionId?permissionId=' + permissionId);
  }

}

