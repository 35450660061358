import { Request } from 'src/app/models/request-message';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocalPagerService } from './local-pager.service';

@Injectable({
  providedIn: 'root'
})
export class ManageWorkerService {

  baseURL = environment.apiUrl;

  constructor(private http: HttpClient, private pagerService: LocalPagerService) { }
 
  
  addWorker(Work) {
    debugger;
    return this.http.post<Request>(this.baseURL + 'ManageWorker/AddWorker', Work);
  }

  updateWorker(Work) {
   
    debugger;
    return this.http.post<Request>(this.baseURL + 'ManageWorker/UpdateWorker', Work);
  }//

  deleteWorker(workerId) {
    debugger;
    return this.http.get<any>(this.baseURL + 'ManageWorker/DeleteWorker?workerId=' + workerId);
  }

  getWorkerById(workerId)
  {
    debugger;
    return this.http.get<any>(this.baseURL + 'ManageWorker/GetWorkerById?workerId=' + workerId);
       
  }
  
}
